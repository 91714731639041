var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            {
              ref: "formInvoice",
              attrs: { model: _vm.form, rules: _vm.rules }
            },
            [_c("InvoiceHeader")],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-card",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "active-tab-key": _vm.activeTabKey,
                "tab-list": _vm.tabsMenu
              },
              on: {
                tabChange: function(key) {
                  return _vm.onTabChange(key)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "customRender",
                  fn: function(item) {
                    return _c("span", {}, [_vm._v(_vm._s(_vm.$t(item.title)))])
                  }
                }
              ])
            },
            [
              _c(
                "transition",
                { attrs: { name: "component-fade", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    [_c(_vm.dynamicComp, { tag: "component" })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c("div", { staticClass: "table__container" }, [
            _c(
              "table",
              {
                staticClass: "table__summary",
                attrs: { "aria-describedby": "table summary invoice" }
              },
              [
                _c("thead", [_c("tr", [_c("th"), _c("th"), _c("th")])]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("lbl_total")) + ":")]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(_vm.calcTotal())) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("lbl_additional_discount")) + ":")
                    ]),
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _c("a-input-number", {
                          staticClass: "mr-2",
                          staticStyle: { width: "90px" },
                          attrs: {
                            placeholder: _vm.$t("lbl_type_here"),
                            formatter: _vm.formatterPercent,
                            parser: _vm.reverseFormatPercent,
                            min: 0,
                            max: 100,
                            precision: 2,
                            disabled: _vm.isUnpaid
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangeDiscount(e, "percent")
                            }
                          },
                          model: {
                            value: _vm.form.additionalDiscount.percent,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form.additionalDiscount,
                                "percent",
                                _vm._n($$v)
                              )
                            },
                            expression: "form.additionalDiscount.percent"
                          }
                        }),
                        _c("a-input-number", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: _vm.$t("lbl_type_here"),
                            formatter: _vm.formatterNumber,
                            parser: _vm.reverseFormatNumber,
                            min: 0,
                            precision: 2,
                            disabled: _vm.isUnpaid
                          },
                          on: {
                            change: function(e) {
                              return _vm.onChangeDiscount(e, "amount")
                            }
                          },
                          model: {
                            value: _vm.form.additionalDiscount.amount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form.additionalDiscount,
                                "amount",
                                _vm._n($$v)
                              )
                            },
                            expression: "form.additionalDiscount.amount"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.$t("lbl_total_applied_prepayment")) + ":"
                      )
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("currency")(_vm.getTotalAppliedPrepayment)
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("lbl_freight")) + ":")]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(_vm.getFreight)) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [
                      _vm._v(_vm._s(_vm.$t("lbl_total_discount")) + ":")
                    ]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("currency")(_vm.getTotalDiscount)) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("lbl_total_tax")) + ":")]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(_vm.getTotalTax)) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t("lbl_grand_total")) + ":")]),
                    _c("td", { attrs: { colspan: "2" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("currency")(_vm.getGrandTotal)) +
                          " "
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ]
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.hasPrivilegeUpdate && _vm.invoiceId
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "danger",
                                disabled: !_vm.documentAbleToCancel,
                                loading: _vm.loading.reject
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleMoreAction({ key: "reject" })
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                          )
                        : _vm._e(),
                      _vm.invoiceId
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "printer",
                                loading: _vm.loading.print
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleMoreAction({ key: "print" })
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.allowQc && _vm.havPrivilegeQC,
                              expression: "allowQc && havPrivilegeQC"
                            }
                          ],
                          attrs: { icon: "form", type: "primary" },
                          on: { click: _vm.handleQC }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_show_qc")) + " ")]
                      ),
                      _vm.hasPrivilegeCreate
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                icon: "save",
                                type: "primary",
                                loading: _vm.loading.submit,
                                disabled: _vm.documentNotAbleToSubmit
                              },
                              on: { click: _vm.validateForm }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.allowQc && _vm.havPrivilegeQC,
                              expression: "allowQc && havPrivilegeQC"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            icon: "check",
                            loading: _vm.loading.approve,
                            disabled: _vm.isUnpaid
                          },
                          on: { click: _vm.handleApprove }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("InvoiceModalPosting", {
        on: { "on-save": _vm.onSaveQc },
        model: {
          value: _vm.modal.posting,
          callback: function($$v) {
            _vm.$set(_vm.modal, "posting", $$v)
          },
          expression: "modal.posting"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }