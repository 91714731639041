
import Vue from "vue";
export default Vue.extend({
  name: "MModalLoading",
  data() {
    return {
      refModal: null as any,
    };
  },
  methods: {
    modalLoadingShow(): void {
      this.refModal = this.refModal = this.$info({
        keyboard: false,
        maskClosable: false,
        title: this.$t("lbl_info_title"),
        content: () => this.$t("lbl_loading_data"),
        okButtonProps: { props: { loading: true } },
        okText: this.$t("lbl_loading").toString(),
      });
    },
    modalLoadingClose(): void {
      this.refModal.destroy();
    },
  },
});
