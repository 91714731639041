export enum AR_INVOICE_STATUS {
  CANCELLED = "Cancelled",
  DRAFT = "Draft",
  FULLY_PAID = "Fully Paid",
  NEED_APPROVAL = "Need Approval",
  PARTIAL_PAID = "Partial Paid",
  POSTED = "Posted",
  UNPAID = "Unpaid",
  RETURNED = "Returned",
}
