











































































































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { formatCurrency, formatterNumber, reverseFormatNumber, formatterPercent, reverseFormatPercent } from "@/validator/globalvalidator";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { Decimal } from "decimal.js-light";
import { invoiceServices } from "@/services-v2/invoice.service";
import { PostInvoice } from "@/models/interface-v2/invoice.interface";
import { printPDF } from "@/helpers/print-js";
import { AR_INVOICE_STATUS } from "@enum/invoice.enum";
import MModalLoadingVue from "@/mixins/MModalLoading.vue";
import moment from "moment";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import localStorageService from "@/services/localStorage.service";
import { APP_DECIMAL_PLACES } from "@/models/enums/global.enum";
import { IAuthorities } from "@/models/interface-v2/auth.interface";

const TAB_LIST = [
  {
    key: "details",
    title: "lbl_details",
    comp: "InvoiceTabDetails",
    scopedSlots: { tab: "customRender" }
  },
  {
    key: "taxDetails",
    title: "lbl_tax_details",
    comp: "InvoiceTabTaxDetails",
    scopedSlots: { tab: "customRender" }
  },
  {
    key: "applyPrepayment",
    title: "lbl_apply_prepayment",
    comp: "InvoiceTabApplyPrepayment",
    scopedSlots: { tab: "customRender" }
  },
  {
    key: "status",
    title: "lbl_status",
    comp: "InvoiceTabStatus",
    scopedSlots: { tab: "customRender" }
  },
];

const ALLOW_TO_CANCEL: string[] = [
  AR_INVOICE_STATUS.DRAFT,
  AR_INVOICE_STATUS.NEED_APPROVAL,
  AR_INVOICE_STATUS.UNPAID,
];

const NOT_ALLOW_TO_SUBMIT: string[] = [
  AR_INVOICE_STATUS.CANCELLED,
  AR_INVOICE_STATUS.FULLY_PAID,
  AR_INVOICE_STATUS.PARTIAL_PAID,
  AR_INVOICE_STATUS.UNPAID,
  AR_INVOICE_STATUS.RETURNED,
];

const ALLOW_TO_QC: AR_INVOICE_STATUS[] =[
  AR_INVOICE_STATUS.NEED_APPROVAL,
];

export default Vue.extend({
  name: "InvoiceIndex",
  components: {
    InvoiceHeader: () => import(/*webpackPrefetch: true */"./components/InvoiceHeader.vue"),
    InvoiceTabDetails: () => import(/*webpackPrefetch: true */"./components/InvoiceTabDetails.vue"),
    InvoiceTabTaxDetails: () => import(/*webpackPrefetch: true */"./components/InvoiceTabTaxDetails.vue"),
    InvoiceTabApplyPrepayment: () => import(/*webpackPrefetch: true */"./components/InvoiceTabApplyPrepayment.vue"),
    InvoiceTabStatus: () => import(/*webpackPrefetch: true */"./components/InvoiceTabStatus.vue"),
    InvoiceModalPosting: () => import(/*webpackPrefetch: true */"./components/InvoiceModalPosting.vue"),
  },
  mixins: [
    MNotificationVue,
    MModalLoadingVue,
  ],
  data() {
    return {
      rules: {
        branchId: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        customerId: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        billAddress: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        shipAddress: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        salesName: [
          { required: false, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        taxType: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        top: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        invoiceDate: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "change" },
        ],
        accountingDate: [
          { required: false, message: () => this.$t("lbl_validation_required_error"), trigger: "change" },
        ],
        currencyId: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        currencyRate: [
          { type: "number", required: false, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        receivableAccountId: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
        invoiceDescription: [
          { required: true, message: () => this.$t("lbl_validation_required_error"), trigger: "blur" },
        ],
      },
      activeTabKey: "details" as "details" | "taxDetails" | "applyPrepayment" | "status",
      invoiceId: "",
      modal: {
        posting: false,
      },
      loading: {
        approve: false,
        reject: false,
        submit: false,
        print: false,
      },
      invoiceStatus: "",
      userPrivileges: [] as IAuthorities[],
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.invoiceStore.form,
      formInvoiceCode: (st: any) => st.invoiceStore.formCurrencyCode,
      tabDetailSource: (st: any) => st.invoiceStore.tabDetailSource,
      tabTaxDetailSource: (st: any) => st.invoiceStore.tabTaxDetailSource,
      tabPrepaymentSource: (st: any) => st.invoiceStore.tabApplyPrepaymentSource,
      reqPost: (st: any) => st.invoiceStore.reqPost,
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
      formInvoiceQc: (st: any) => st.invoiceStore.formInvoiceQc,
    }),
    ...mapGetters({
      getTotalDiscount: "invoiceStore/GET_TOTAL_DISCOUNT",
      getTotalTax: "invoiceStore/GET_TOTAL_TAX",
      getTotal: "invoiceStore/GET_TOTAL",
      getGrandTotal: "invoiceStore/GET_GRAND_TOTAL",
      getFormInvoiceQc: "invoiceStore/GET_FORM_INVOICE_QC",
      getFreight: "invoiceStore/GET_FREIGHT",
      // getSumGrossAfterDiscount: "invoiceStore/GET_SUM_GROSS_AFTER_DISCOUNT",
      getTotalAppliedPrepayment: "invoiceStore/GET_TOTAL_APPLIED_PREPAYMENT",
    }),
    documentNotAbleToSubmit(): boolean {
      return NOT_ALLOW_TO_SUBMIT.includes(this.detailInvoice.status);
    },
    documentAbleToCancel(): boolean {
      return ALLOW_TO_CANCEL.includes(this.detailInvoice.status);
    },
    dynamicComp(): string {
      return TAB_LIST.find(x => x.key === this.activeTabKey)?.comp || "";
    },
    isTaxNone(): boolean {
      return this.form.taxType === TAX_CALCULATION.NONE;
    },
    tabsMenu() {
      if (this.isTaxNone) {
        return TAB_LIST.filter(x => x.key !== "taxDetails");
      }
      return TAB_LIST;
    },
    isIinvoiceDraft(): boolean {
      return this.detailInvoice === AR_INVOICE_STATUS.DRAFT;
    },
    isDetail(): boolean {
      return !!this.detailInvoice.status;
    },
    isWaitForApproval(): boolean {
      return this.detailInvoice.status === AR_INVOICE_STATUS.NEED_APPROVAL;
    },
    isUnpaid(): boolean {
      return this.detailInvoice.status === AR_INVOICE_STATUS.UNPAID;
    },
    havPrivilegeQC(): IAuthorities | undefined {
      return this.userPrivileges.find(x => x.key === "invoice-ar-qc" && x.privilege.update);
    },
    hasPrivilegeCreate(): IAuthorities | undefined {
      return this.userPrivileges.find(x => x.key === "invoice-ar" && x.privilege.create);
    },
    hasPrivilegeUpdate(): IAuthorities | undefined {
      return this.userPrivileges.find(x => x.key === "invoice-ar" && x.privilege.update);
    },
    allowQc(): boolean {
      return ALLOW_TO_QC.includes(this.detailInvoice.status as AR_INVOICE_STATUS);
    },
  },
  watch: {
    formInvoiceCode(newVal): void {
      if (newVal && newVal !== "IDR") {
        this.rules.currencyRate[0].required = true;
      } else {
        this.rules.currencyRate[0].required = false;
      }
    },
    /**
     * trick to avoid validation issue
     * on invoice date. every time invoiceDate
     * has a value, should clear validation.
     * if not, validation messages will show up
     */
    "form.invoiceDate"(newVal): void {
      if (newVal) {
        const refs = this.$refs.formInvoice as FormModel;
        refs.clearValidate("invoiceDate");
      }
    },
  },
  beforeDestroy() {
    this.resetStoreInvoice();
  },
  created() {
    if (this.$route.query && this.$route.query.status) {
      // do something with status based
      this.invoiceStatus = (this.$route.query.status as string);
    }
    if (this.$route.params.id) {
      this.invoiceId = this.$route.params.id;
      this.getDetailInvoice(this.invoiceId);
    }
    this.loadUserPrivileges();
  },
  methods: {
    moment,
    formatCurrency,
    formatterNumber,
    formatterPercent,
    reverseFormatNumber,
    reverseFormatPercent,
    ...mapActions({
      resetForm: "invoiceStore/RESET_FORM",
      resetReqPost: "invoiceStore/RESET_REQ_POST",
      resetStoreInvoice: "invoiceStore/RESET_STORE_INVOICE",
      calcProRatePricing: "invoiceStore/CALC_PRO_RATE_PRICING_V2",
    }),
    ...mapMutations({
      setForm: "invoiceStore/SET_FORM",
      setDetailInvoice: "invoiceStore/SET_DETAIL_INVOICE",
      setInvoiceResource: "invoiceStore/SET_INVOICE_RESOURCE",
    }),
    loadUserPrivileges(): void {
      const privileges = localStorageService.loadUserPrivilege();
      this.userPrivileges = privileges;
    },
    validateForm(): void {
      const theForm = this.$refs.formInvoice as FormModel;
      theForm.validate((valid: boolean) => {
        if (!valid) {
          this.showNotifValidationError();
        } else {
          if (this.invoiceId) {
            this.handleUpdate();
          } else {
            this.handleSubmit();
          }
        }
      });
    },
    async handleApprove(): Promise<void> {
      try {
        if (!this.invoiceId) {
          throw new Error();
        }
        this.loading.approve = true;
        await invoiceServices.approveQcInvoice(this.invoiceId);
        this.showNotifSuccess("notif_approve_success");
        this.getDetailInvoice(this.invoiceId);
      } catch (error) {
        this.showNotifError("notif_approve_fail");
      } finally {
        this.loading.approve = false;
      }
    },
    async handleUpdate(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload = this.createReq();
        await invoiceServices.updateInvoice(payload, this.invoiceId);
        this.showNotifSuccess("notif_update_success");
        this.getDetailInvoice(this.invoiceId);
      } catch (error) {
        this.showNotifError("notif_update_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async handleSubmit(): Promise<void> {
      try {
        this.loading.submit = true;
        const payload = this.createReq();
        const { id, documentNumber, status } = await invoiceServices.createInvoice(payload);
        this.showSubmitSuccessMesssage(documentNumber);
        this.invoiceId = id;
        this.$router.replace({
          name: "accountreceivables.invoices.edit",
          params: { id },
          query: { status }
        });
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loading.submit = false;
      }
    },
    createReq(): PostInvoice {
      const {
        branchId, customerId, billAddress,
        shipAddress, invoiceDate, currencyId,
        receivableAccountId, deliveryOrderIds,
        salesName, taxType, top, accountingDate,
        invoiceDescription, currencyRate,
        additionalDiscount, deletedInvoiceARLineIds,
      } = this.form;
      const tabTax = this.tabTaxDetailSource;
      const tabDetail = this.tabDetailSource;
      const tabPrepayment = this.tabPrepaymentSource;
      const dp = localStorageService.load(APP_DECIMAL_PLACES.DP) ?? DECIMAL_PLACES_CURRENCY;
      return {
        accountingDate: accountingDate,
        applyPrepayment: {
          prepaymentLines: tabPrepayment.map(x => ({
            appliedAmount: x.amount ? new Decimal(x.amount).toDecimalPlaces(2).toNumber() : 0,
            description: x.description || null,
            invoicePrepaymentId: x.invoicePrepaymentNumberId || null,
            id: x.id,
          })),
          deletedPrepaymentLineIds: [],
        },
        usageType: "",
        icBillingId: "",
        deletedInvoiceARLineIds,
        branchWarehouseId: branchId,
        currencyId: currencyId,
        currencyRate: currencyRate,
        customerBillToAddress: billAddress,
        customerId: customerId,
        customerShipToAddress: shipAddress,
        customerTaxType: tabTax.taxType,
        deliveryOrderIds,
        description: invoiceDescription,
        invoiceARLines: tabDetail.map(x => ({
          id: x.id,
          alias: x.alias || null,
          baseAmount: new Decimal(x.baseAmount).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() || 0,
          deliveryOrderLineId: x.deliveryOrderLineId || null,
          description: x.description || null,
          discountValue: new Decimal(x.discountValue).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() || 0,
          documentReference: x.documentReference || null,
          incomeAccountTaxId: x.incomeTaxId || null, // get from modal more
          merk: x.brand || null,
          percentDiscount: new Decimal(x.percentDiscount).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() || 0,
          price: x.price || 0,
          productId: x.productId || null,
          qty: x.qty || 0,
          revenueAccountId: x.revenueAccountId || null,
          taxId: x.taxId || null,
          taxValue: new Decimal(x.taxAmount).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() || 0,
          uomId: x.uomId || null,
          batchNumberId: x.batchNumberId || null,
          subTotal: new Decimal(x.subTotal).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() || 0,
        })),
        invoiceDate: invoiceDate,
        receivableAccountId: receivableAccountId,
        taxInvoiceNumber: tabTax.taxInvoiceNumber, // ask
        salesName,
        taxInvoiceDate: this.moment(tabTax.taxInvoiceDate).format(),
        taxIsUploaded: tabTax.taxInvoiceUploaded,
        taxRegistrationName: tabTax.taxRegistrationName,
        taxRegistrationNumber: tabTax.taxRegistrationNumber,
        taxType,
        termOfPayment: +top,
        discountValue: additionalDiscount.amount ? new Decimal(additionalDiscount.amount).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() : 0,
        percentDiscount: additionalDiscount.percent ? new Decimal(additionalDiscount.percent).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).toNumber() : 0,
      };
    },
    onTabChange(key: "details" | "taxDetails" | "applyPrepayment" | "status"): void {
      this.activeTabKey = key;
    },
    handleBack(): void {
      this.$router.push({ name: "accountreceivables.invoices" });
    },
    async handleReject(): Promise<void> {
      const confirm: boolean = await this.showConfirmation();
      if (!confirm) return;
      try {
        if (!this.invoiceId) return;
        this.loading.reject = true;
        await invoiceServices.cancelInvoice(this.invoiceId);
        this.showSuccessMessage("notif_cancel_success");
        this.handleBack();
      } catch (error) {
        this.showErrorMessage("notif_cancel_fail");
      } finally {
        this.loading.reject = false;
      }
    },
    async handlePrint(): Promise<void> {
      try {
        if (!this.invoiceId) return;
        this.modalLoadingShow();
        this.loading.print = true;
        const file = await invoiceServices.printInvoice(this.invoiceId);
        printPDF(new Blob([file]), () => this.showErrorMessage("error_print_fail"));
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.modalLoadingClose();
        this.loading.print = false;
      }
    },
    calcTotal(): number {
      const total = this.tabDetailSource.reduce((a, b) => {
        const gross = new Decimal(b.qty || 0).times(b.price ||0);
        return gross.plus(a).toNumber();
      }, 0);
      this.form.total = total;
      return total;
    },
    onChangeDiscount(e: number, source: "amount" | "percent"): void {
      if (source === "percent") {
        const percentage = new Decimal(e || 0).dividedBy(100).toDecimalPlaces(DECIMAL_PLACES_CURRENCY, 2);
        const final = new Decimal(this.calcTotal() || 0).times(percentage).toNumber();
        this.setForm({
          ...this.form,
          additionalDiscount: {
            percent: e,
            amount: final
          },
        });
      } else {
        const value = new Decimal(e || 0);
        const final = value.dividedBy(this.calcTotal() || 1).times(100).toNumber();
        this.setForm({
          ...this.form,
          additionalDiscount: {
            percent: final,
            amount: e,
          },
        });
      }
      this.calcProRatePricing();
    },
    handleQC(): void {
      this.modal.posting = true;
    },
    handleMoreAction({ key }: {key: "print" | "reject"}): void {
      if (key === "print") {
        this.handlePrint();
      } else if (key === "reject") {
        this.handleReject();
      }
    },
    async getDetailInvoice(invoiceId: string): Promise<void> {
      try {
        if (!invoiceId) return;
        const res = await invoiceServices.getDetailInvoiceAR(invoiceId);
        this.setDetailInvoice(res);
        this.setInvoiceResource();
        // this.calcProRatePricing();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    onSaveQc(): void {
      this.getDetailInvoice(this.invoiceId);
    },
  },
});
